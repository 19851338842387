import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  CheckCircleOutlined,
  CreditCardOutlined,
  EditOutlined,
  FileDoneOutlined,
  FormOutlined,
  ScanOutlined,
  SecurityScanOutlined,
  UserDeleteOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { Modal, StepProps } from 'antd';
import { useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  BasicActions,
  FinishActions,
  FinishContent,
  GuestInstructionsActions,
  IntroContent,
  IntroMedia,
  KioskStep,
  NdaActions,
  PinActions,
  QrActions,
  QrContent,
  RegisterActions,
  SecurityRegulationsActions,
} from '../../components/Kiosk/KioskSteps';

import { useAppDispatch, useAppSelector } from '../App/useRedux';
import { PinPad } from '../../components/Kiosk/PinPad';
import { RegisterPage } from '../../components/Kiosk/RegisterPage';
import { QrCodeScanner } from '../../components/Kiosk/QrCodeScanner';
import { Translated } from '../../components/UI/Core';
import { appLanguages } from '../../constants/AppLanguages/AppLanguages';
import { switchLanguage } from '../../store/AppSettings/AppSettings.redux';

// Models
import { Media, Text } from '../../models/Configurations';

// Utils
import { useData } from '../App/useData';
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { RegistrationFlow, Step } from '../../models/enums/Step';
import { KioskMediaType } from '../../models/enums/KioskMediaType';
import { GuestInstructions } from '../../components/Kiosk/GuestInstructions';
import { SecurityRegulations } from '../../components/Kiosk/SecurityRegulations';
import { prepareSignature, updateVisitStatus } from '../../store/Visits/Visits.redux';
import { GuestStatus } from '../../models/enums/GuestStatus';
import { useStepBuilder } from './useStepBuilder';
import { useTenantData } from '../App/useTenantData';
import { changeAccount } from '../../store/Account/Account.redux';
import { KioskActionCard } from '../../components/Kiosk/KioskActionsCard';
import { Nda } from '../../components/Kiosk/Nda';

const { confirm } = Modal;

type OnCompleteFunction = () => Promise<boolean>;

const DefaultCompletionBehavior = () => Promise.resolve(true);

// Hook
export const useKioskSteps = () => {
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { data: configuration, fetch, pending } = useData(ApiEndpoints.kiosk.getConfiguration, null);
  const { getTenantDataProps } = useTenantData();

  const { selfRegistrationSteps, preRegistrationSteps } = useStepBuilder(configuration);

  // State
  const [stepIndex, setStepIndex] = useState(0);
  const [usePin, setUsePin] = useState(false);
  const [onCompleteCallback, setOnCompleteCallback] = useState<OnCompleteFunction>(() => DefaultCompletionBehavior); // Setting state accepts a callback function, so set it to a function that returns the callback function...

  // Calculated
  const currentLocale = useAppSelector(({ appSettings }) => appSettings?.locale);
  const registrationFlow = useAppSelector(({ appSettings }) => appSettings.registrationFlow);
  const guest = useAppSelector(({ visits }) => visits.guest);
  const targetLocale = useMemo(() => {
    const currentIndex = appLanguages.findIndex((x) => x.languageId === currentLocale?.languageId);
    return appLanguages[(currentIndex + 1) % appLanguages.length];
  }, [currentLocale]);

  // Callbacks
  const setSignature = useCallback(
    (signature: string | undefined, media: Media | undefined) => {
      if (signature === undefined || media === undefined) {
        dispatch(prepareSignature(undefined));
      } else {
        dispatch(
          prepareSignature({
            signatureBase64: signature,
            lang: media.Language,
            mediaType: media.MediaType,
          })
        );
      }
    },
    [dispatch]
  );
  const cleanupAfterNavigation = useCallback(() => {
    setOnCompleteCallback(() => DefaultCompletionBehavior);
    setUsePin(false);
  }, [setOnCompleteCallback, setUsePin]);

  const onSwitchLocale = useCallback(() => {
    dispatch(switchLanguage(targetLocale));
  }, [targetLocale, dispatch]);

  const onNext = useCallback(async () => {
    let registrationFlowIndex: number;
    let nextStepIndex: number;

    switch (registrationFlow) {
      case RegistrationFlow.SelfRegistration:
        registrationFlowIndex = selfRegistrationSteps.findIndex((x) => x.Index === stepIndex);

        if (registrationFlowIndex >= selfRegistrationSteps.length) {
          return;
        }

        nextStepIndex = selfRegistrationSteps[registrationFlowIndex + 1].Index;
        break;
      case RegistrationFlow.PreRegistration:
      default:
        registrationFlowIndex = preRegistrationSteps.findIndex((x) => x.Index === stepIndex);

        if (registrationFlowIndex >= preRegistrationSteps.length) {
          return;
        }

        nextStepIndex = preRegistrationSteps[registrationFlowIndex + 1].Index;
        break;
    }

    if (await onCompleteCallback()) {
      setStepIndex(nextStepIndex);
      cleanupAfterNavigation();
    }
  }, [
    stepIndex,
    registrationFlow,
    onCompleteCallback,
    selfRegistrationSteps,
    preRegistrationSteps,
    cleanupAfterNavigation,
  ]);

  const onPrev = useCallback(() => {
    let registrationFlowIndex: number;
    let prevStepIndex: number;

    switch (registrationFlow) {
      case RegistrationFlow.SelfRegistration:
        registrationFlowIndex = selfRegistrationSteps.findIndex((x) => x.Index === stepIndex);

        if (registrationFlowIndex <= 0) {
          return;
        }

        prevStepIndex = selfRegistrationSteps[registrationFlowIndex - 1].Index;
        break;
      case RegistrationFlow.PreRegistration:
      default:
        registrationFlowIndex = preRegistrationSteps.findIndex((x) => x.Index === stepIndex);

        if (registrationFlowIndex <= 0) {
          return;
        }

        prevStepIndex = preRegistrationSteps[registrationFlowIndex - 1].Index;
        break;
    }

    setStepIndex(prevStepIndex);
    cleanupAfterNavigation();
  }, [registrationFlow, cleanupAfterNavigation, selfRegistrationSteps, preRegistrationSteps, stepIndex]);

  const onGoTo = useCallback(
    (step: Step) => {
      let index = 0;

      switch (registrationFlow) {
        case RegistrationFlow.SelfRegistration:
          index = selfRegistrationSteps.find((x) => x.StepName === step)?.Index ?? 0;
          break;
        case RegistrationFlow.PreRegistration:
        default:
          index = preRegistrationSteps.find((x) => x.StepName === step)?.Index ?? 0;
          break;
      }

      setStepIndex(index);
      cleanupAfterNavigation();
    },
    [registrationFlow, cleanupAfterNavigation, selfRegistrationSteps, preRegistrationSteps]
  );

  const onShowPinPad = useCallback(() => {
    setUsePin(true);
  }, []);

  const registerCompleteCallback = useCallback(
    (callback: () => Promise<boolean>) => {
      setOnCompleteCallback(() => callback); // Setting state accepts a callback function, so set it to a function that returns the callback function...
    },
    [setOnCompleteCallback]
  );

  // Customization
  const onReject = useCallback(() => {
    confirm({
      title: intl.formatMessage({
        id: 'kiosk.securityRegulations.decline',
      }),
      icon: <UserDeleteOutlined />,
      content: intl.formatMessage({
        id: 'kiosk.securityRegulations.declineSub',
      }),
      okText: intl.formatMessage({
        id: 'app.yes',
        defaultMessage: 'Yes',
      }),
      cancelText: intl.formatMessage({
        id: 'app.no',
        defaultMessage: 'No',
      }),
      okType: 'danger',
      onOk: () => {
        if (registrationFlow === RegistrationFlow.SelfRegistration) {
          onGoTo(Step.Welcome);
        } else if (registrationFlow === RegistrationFlow.PreRegistration) {
          if (guest) {
            dispatch(
              updateVisitStatus({
                Id: guest.Visit.Id,
                GuestId: guest.Visitor.Id,
                GuestStatus: GuestStatus.Declined,
                HostId: null,
                HostStatus: null,
              })
            );
            onGoTo(Step.Welcome);
          }
        }
      },
      onCancel: () => null,
    });
  }, [intl, registrationFlow, onGoTo, guest, dispatch]);

  // Actions
  const stepActions = useMemo(
    () => ({
      onNext,
      onPrev,
      onGoTo,
      onSwitchLocale,
      onShowPinPad,
      targetLocale,
      onReject,
      isConfigured: !!configuration,
      pending,
      onFetchConfiguration: () => {
        if (id !== undefined) {
          fetch({ id });
        }
      },
    }),
    [onNext, onPrev, onGoTo, onSwitchLocale, onShowPinPad, targetLocale, onReject, configuration, pending, fetch, id]
  );

  // Effects
  useEffect(() => {
    const tenantData = getTenantDataProps();
    if (configuration && tenantData.tenantData) {
      if (configuration.TenantId !== tenantData.tenantData.Id) {
        // This here is an example of how NOT to fix a race condition.
        // We can only dispatch this when signalR is connected, otherwise no redirect happens.
        setTimeout(() => {
          dispatch(
            changeAccount({
              formValues: {
                NewTenant: configuration.Tenant,
              },
            })
          );
        }, 5000);
      }
    }
  }, [configuration, getTenantDataProps, dispatch]);

  useEffect(() => {
    // Fetch on initializing
    if (id !== undefined) {
      fetch({ id });
    }
  }, [fetch, id]);

  // Props
  const getIntroProps = useCallback(
    () => ({
      media:
        configuration?.Media.find(
          (x) => x.MediaType === KioskMediaType.Banner && x.Language === currentLocale?.locale
        ) ??
        configuration?.Media.find(
          (x) => x.MediaType === KioskMediaType.Banner && x.Language === configuration?.DefaultLanguage
        ),
      pending,
    }),
    [configuration, currentLocale?.locale, pending]
  );

  const getTextProps = useCallback(
    () => ({
      text:
        configuration?.Texts.find((config) => config.Language === currentLocale?.locale) ??
        configuration?.Texts.find((config) => config.Language === configuration?.DefaultLanguage) ??
        ({} as Text),
      pending,
    }),
    [currentLocale, configuration, pending]
  );

  const getSecurityRegulationProps = useCallback(
    () => ({
      media:
        configuration?.Media.find(
          (x) => x.MediaType === KioskMediaType.SecurityRegulation && x.Language === currentLocale?.locale
        ) ??
        configuration?.Media.find(
          (x) => x.MediaType === KioskMediaType.SecurityRegulation && x.Language === configuration?.DefaultLanguage
        ),
      pending,
    }),
    [configuration, currentLocale?.locale, pending]
  );

  const getGuestInstructionProps = useCallback(() => {
    return {
      media:
        configuration?.Media.find(
          (x) => x.MediaType === KioskMediaType.GuestInstructions && x.Language === currentLocale?.locale
        ) ??
        configuration?.Media.find(
          (x) => x.MediaType === KioskMediaType.GuestInstructions && x.Language === configuration?.DefaultLanguage
        ),
      pending,
    };
  }, [configuration, currentLocale?.locale, pending]);

  const getNdaProps = useCallback(
    () => ({
      media:
        configuration?.Media.find((x) => x.MediaType === KioskMediaType.Nda && x.Language === currentLocale?.locale) ??
        configuration?.Media.find(
          (x) => x.MediaType === KioskMediaType.Nda && x.Language === configuration?.DefaultLanguage
        ),
      pending,
    }),
    [configuration, currentLocale?.locale, pending]
  );

  // Step List
  const steps: KioskStep[] = useMemo(() => {
    const filteredSteps = [
      {
        title: <Translated id="kiosk.step.welcome" />,
        icon: <UserOutlined />,
        cover: <IntroMedia {...getIntroProps()} />,
        content: <IntroContent {...getTextProps()} />,
        actions: [<BasicActions key="Welcome" {...stepActions} />],
        step: Step.Welcome,
      },
      {
        title: <Translated id="kiosk.step.scan" />,
        icon: <ScanOutlined />,
        disabled: true,
        cover: !usePin ? <QrCodeScanner onNext={stepActions.onNext} onGoTo={stepActions.onGoTo} /> : '',
        content: !usePin ? QrContent : <PinPad onNext={stepActions.onNext} onGoTo={stepActions.onGoTo} />,
        actions: [
          !usePin ? (
            <QrActions key="Authentication" {...stepActions} />
          ) : (
            <PinActions key="Authentication" {...stepActions} />
          ),
        ],
        coverSize: 60,
        step: Step.QrOrPin,
      },
      {
        title: <Translated id="kiosk.step.securityRegulations" />,
        icon: <SecurityScanOutlined />,
        disabled: true,
        content: <SecurityRegulations {...getSecurityRegulationProps()} />,
        actions: [<SecurityRegulationsActions key="Authentication" {...stepActions} />],
        step: Step.SecurityRegulations,
      },
      {
        title: <Translated id="kiosk.step.nda" />,
        icon: <EditOutlined />,
        disabled: true,
        content: (
          <Nda {...getNdaProps()} setCompletionCallback={registerCompleteCallback} setSignature={setSignature} />
        ),
        actions: [<NdaActions key="Authentication" {...stepActions} />],
        step: Step.Nda,
      },
      {
        title: <Translated id="kiosk.step.register" />,
        icon: <FormOutlined />,
        disabled: true,
        content: <RegisterPage setCompletionCallback={registerCompleteCallback} onNext={stepActions.onNext} />,
        actions: [<RegisterActions key="Authentication" {...stepActions} />],
        step: Step.Register,
      },
      {
        title: <Translated id="kiosk.step.guestInstructions" />,
        icon: <FileDoneOutlined />,
        disabled: true,
        content: <GuestInstructions {...getGuestInstructionProps()} />,
        actions: [<GuestInstructionsActions key="Authentication" {...stepActions} />],
        step: Step.GuestInstructions,
      },
    ];

    const finishStep = {
      title: <Translated id="kiosk.step.enter" />,
      icon: <CheckCircleOutlined />,
      content: <FinishContent {...getTextProps()} />,
      disabled: true,
      actions: [<FinishActions key="Done" {...stepActions} />],
      step: Step.Finish,
    };

    const actionSteps: KioskStep[] = [];

    configuration?.Actions.forEach((action) => {
      const text = action.Texts.find((actionText) => actionText.Language === currentLocale?.locale);
      actionSteps.push({
        title: text?.Title,
        icon: <CreditCardOutlined />,
        disabled: true,
        content: (
          <KioskActionCard
            key={action.Id}
            actionText={action.Texts.find((x) => x.Language === currentLocale?.locale)}
            actionId={action.ActionId}
            iconId={action.Icon}
            onNext={stepActions.onNext}
          />
        ),
        step: Step.Card,
      } as KioskStep);
    });

    let finalSteps = [...filteredSteps, ...actionSteps, finishStep];

    if (configuration) {
      const hasSecurityQuestions =
        configuration.EnableSecurityRegulations &&
        (configuration.Media.filter((x) => x.MediaType === KioskMediaType.SecurityRegulation).length ===
          appLanguages.length ||
          configuration.Media.some(
            (x) => x.MediaType === KioskMediaType.SecurityRegulation && x.Language === configuration.DefaultLanguage
          ));

      const hasGuestInstructions =
        configuration.EnableGuestInstructions &&
        (configuration.Media.filter((x) => x.MediaType === KioskMediaType.GuestInstructions).length ===
          appLanguages.length ||
          configuration.Media.some(
            (x) => x.MediaType === KioskMediaType.GuestInstructions && x.Language === configuration.DefaultLanguage
          ));

      const hasNda =
        configuration.EnableNda &&
        (configuration.Media.filter((x) => x.MediaType === KioskMediaType.Nda).length === appLanguages.length ||
          configuration.Media.some(
            (x) => x.MediaType === KioskMediaType.Nda && x.Language === configuration.DefaultLanguage
          ));

      if (!hasSecurityQuestions) {
        finalSteps = finalSteps.filter((x) => x.step !== Step.SecurityRegulations);
      }

      if (!hasGuestInstructions) {
        finalSteps = finalSteps.filter((x) => x.step !== Step.GuestInstructions);
      }

      if (!hasNda) {
        finalSteps = finalSteps.filter((x) => x.step !== Step.Nda);
      }
    }

    return finalSteps;
  }, [
    getIntroProps,
    getTextProps,
    stepActions,
    usePin,
    getSecurityRegulationProps,
    getNdaProps,
    registerCompleteCallback,
    getGuestInstructionProps,
    setSignature,
    configuration,
    currentLocale?.locale,
  ]);

  // Hook Data
  return useMemo(
    () => ({
      steps: steps.map(({ coverSize, ...rest }) => rest as StepProps),
      step: { ...steps[stepIndex], index: stepIndex },
      stepActions,
    }),
    [steps, stepIndex, stepActions]
  );
};
