import React, { useCallback, useEffect, useMemo } from 'react';
import { Button, Card } from 'antd';
import styled from 'styled-components';
import { usePrevious } from 'react-use';
import { KioskActionText } from '../../models/KioskAction';
import { useAppDispatch, useAppSelector } from '../../hooks/App/useRedux';
import { PersonType } from '../../models/enums/PersonType';
import { executeAction } from '../../store/Visits/Visits.redux';

// Styled components
const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 16px;
`;

const StyledCard = styled(Card)`
  width: 320px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease, transform 0.3s ease;

  &:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
    transform: translateY(-5px);
  }
`;

const IconContainer = styled.div`
  font-size: 72px;
  color: #81c8de;
  margin-bottom: 16px;
`;

const ActionButton = styled(Button)`
  margin-top: 16px;
  width: 100%;
  background-color: #1890ff;
  border: none;
  border-radius: 8px;
  font-weight: bold;

  &:hover {
    background-color: #40a9ff;
  }
`;

interface KioskActionProps {
  actionText?: KioskActionText;
  actionId?: string;
  iconId: number;
  onNext: () => void;
}

const icons: { [key: number]: string } = {
  1: 'fa-door-closed',
  2: 'fa-credit-card',
  3: 'fa-id-badge',
};

export const KioskActionCard = ({ actionText, actionId, iconId, onNext }: KioskActionProps) => {
  const iconClass = icons[iconId];
  const dispatch = useAppDispatch();
  const { visitorIsInPacs, actionExecuting, actionError, lastVisitorId } = useAppSelector(({ visits }) => visits);

  const previousActionExecuting = usePrevious(actionExecuting);

  // Effects
  useEffect(() => {
    if (previousActionExecuting === true && actionExecuting === false && actionError === false) {
      onNext();
    }
  }, [actionExecuting, previousActionExecuting, actionError, onNext]);

  const onButtonPress = useCallback(() => {
    if (actionExecuting || !actionId || !lastVisitorId || !visitorIsInPacs) return;

    dispatch(
      executeAction({
        ActionId: actionId,
        PersonId: lastVisitorId,
        PersonType: PersonType.Visitor,
      })
    );
  }, [dispatch, actionId, lastVisitorId, actionExecuting, visitorIsInPacs]);

  return useMemo(
    () => (
      <Wrapper>
        <StyledCard title={<span style={{ fontWeight: 'bold', fontSize: '16px' }}>{actionText?.Title}</span>}>
          <IconContainer>
            {actionExecuting ? <i className="fas fa-circle-notch fa-spin" /> : <i className={`fas ${iconClass}`} />}
          </IconContainer>
          <p>{actionText?.Subtext}</p>
          {actionExecuting ? (
            <ActionButton type="primary">{actionText?.LoadingText || 'Default Button Text'}</ActionButton>
          ) : (
            <ActionButton onClick={onButtonPress} type="primary" disabled={!visitorIsInPacs}>
              {actionText?.ButtonText || 'Default Button Text'}
            </ActionButton>
          )}
        </StyledCard>
      </Wrapper>
    ),
    [actionText, iconClass, actionExecuting, onButtonPress, visitorIsInPacs]
  );
};
