import React, { useCallback, useEffect, useMemo } from 'react';
import { Checkbox, Col, Divider, Form, Input, Row, Typography } from 'antd';

import { useIntl } from 'react-intl';
import styled from 'styled-components';
import { isEmpty } from 'lodash';
import moment from 'moment';

// Components
import { useParams } from 'react-router-dom';
import { Translated } from '../UI/Core';
import { Flex } from '../UI/Base';
import { useAppDispatch } from '../../hooks/App/useRedux';
import { createVisit } from '../../store/Visits/Visits.redux';
import { GuestStatus } from '../../models/enums/GuestStatus';
import { useData } from '../../hooks/App/useData';
import { ApiEndpoints } from '../../data/ApiEndpoints';
import { Identifier } from '../../models/Visits/Identifier';

const { Title } = Typography;

interface RegisterPageProps {
  onNext: () => void;
  setCompletionCallback: (callback: () => Promise<boolean>) => void;
}

export const RegisterPage = ({ onNext, setCompletionCallback }: RegisterPageProps) => {
  const [form] = Form.useForm();
  const intl = useIntl();
  const dispatch = useAppDispatch();
  const { data: identifiers, fetch } = useData(ApiEndpoints.identifierTypes.get, null);
  const { id } = useParams();

  useEffect(() => {
    if (id !== undefined) {
      fetch({ id });
    }
  }, [fetch, id]);

  const identifiersData = useMemo(
    () =>
      identifiers?.map((identifier) => ({
        id: identifier.Id,
        name: identifier.Name,
        valueLabel: identifier.ValueLabel,
        displayLabel: identifier.DisplayLabel,
        defaultForCheckIn: identifier.DefaultForCheckIn,
        requiredInSelfRegistration: identifier.RequiredInSelfRegistration,
      })) ?? [],
    [identifiers]
  );
  // Callback executed before navigation to next page
  const onCompletion = useCallback(async () => {
    try {
      const formValues = await form.validateFields();
      // add data
      dispatch(
        createVisit({
          Id: '',
          Title: formValues.Title,
          Description: formValues.Description,
          ValidFrom: moment().format(),
          ValidTo: moment().add(4, 'hours').format(),
          VisitType: 0,
          Hosts: [],
          Status: GuestStatus.CheckedIn,
          KioskId: id ?? '',
          Guests: [
            {
              Id: '',
              FullName: '',
              FirstName: formValues.FirstName,
              Prefix: formValues.Prefix,
              LastName: formValues.LastName,
              Email: formValues.Email,
              Status: GuestStatus.CheckedIn,
              EmailSent: false,
              CompanyName: formValues.CompanyName,
              PhoneNumber: formValues.PhoneNumber,
              Identifiers: formValues.Identifiers?.filter((x: Identifier) => x.Value).map((x: Identifier) => ({
                Value: x.Value,
                Name: x.Name ?? '',
                Active: true,
                IdentifierTypeId: x.Id,
              })),
            },
          ],
        })
      );
    } catch (e) {
      return false;
    }
    return true;
  }, [dispatch, form, id]);

  // Add callback
  useEffect(() => {
    setCompletionCallback(onCompletion);
  }, [onCompletion, setCompletionCallback]);

  // Styled UI Components
  const StyledForm = styled(Form)`
    display: flex;
    flex-direction: column;
    height: 100%;
  `;

  const StyledFormItem = styled(Form.Item)`
    font-weight: 600;
    text-align: start;
  `;

  const StyledInput = styled(Input)`
    padding: 12px;
    box-shadow: 0 8px 6px -11px black;
  `;

  const StyledContainer = styled('div')`
    width: 100%;
    @media screen and (min-width: 992px) {
      padding-left: 12%;
      padding-right: 12%;
    }
  `;

  const StyledCheckbox = styled(Checkbox)`
    text-align: left;
    font-weight: 600;
  `;

  return (
    <Flex direction="column">
      <Title level={2}>Register</Title>
      <Divider />

      <StyledContainer>
        <StyledForm layout="vertical" form={form} onFinish={onNext} autoComplete="off">
          {/* Visit Details */}
          <Col span={24}>
            <h1 style={{ marginBottom: 15, marginTop: 30 }}>
              <Translated id="visits.form.details" />{' '}
            </h1>
            <Divider />
          </Col>
          <Row style={{ flexDirection: 'row' }}>
            <Col xs={24} sm={24} md={12}>
              <StyledFormItem
                name="Title"
                label={
                  <p style={{ fontSize: '1rem', marginBottom: '0' }}>
                    <Translated id="visits.form.title" />
                  </p>
                }
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'visits.form.warnings.title' }),
                  },
                ]}
              >
                <StyledInput
                  placeholder={intl.formatMessage({ id: 'visits.form.title.placeholder' })}
                  autoComplete="off"
                />
              </StyledFormItem>
            </Col>
            <Col xs={24} sm={24} md={12}>
              <StyledFormItem
                name="Description"
                label={
                  <p style={{ fontSize: '1rem', marginBottom: '0' }}>
                    <Translated id="visits.form.description" />
                  </p>
                }
              >
                <StyledInput
                  placeholder={intl.formatMessage({ id: 'visits.form.description.placeholder' })}
                  autoComplete="off"
                />
              </StyledFormItem>
            </Col>
          </Row>
          {/* Guest Details */}
          <Col span={24}>
            <h1 style={{ marginBottom: 15, marginTop: 10 }}>
              <Translated id="visitors.form.details" />
            </h1>
            <Divider />
          </Col>
          {/* Last Name & Prefix */}
          <Row style={{ flexDirection: 'row' }}>
            <Col xs={24} sm={24} md={18}>
              <StyledFormItem
                name="FirstName"
                label={
                  <p style={{ fontSize: '1rem', marginBottom: '0' }}>
                    <Translated id="visitors.firstName" />
                  </p>
                }
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'visitors.form.warnings.firstName' }),
                  },
                ]}
              >
                <StyledInput placeholder="Hubert" autoComplete="off" />
              </StyledFormItem>
            </Col>
            <Col xs={24} sm={24} md={6}>
              <StyledFormItem
                name="Prefix"
                label={
                  <p style={{ fontSize: '1rem', marginBottom: '0' }}>
                    <Translated id="visitors.prefix" />
                  </p>
                }
              >
                <StyledInput placeholder="van der" autoComplete="off" />
              </StyledFormItem>
            </Col>
          </Row>

          {/* Last Name */}
          <Row style={{ flexDirection: 'row' }}>
            <Col span={24}>
              <StyledFormItem
                name="LastName"
                label={
                  <p style={{ fontSize: '1rem', marginBottom: '0' }}>
                    <Translated id="visitors.lastName" />
                  </p>
                }
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'visitors.form.warnings.lastName' }),
                  },
                ]}
              >
                <StyledInput placeholder="Renner" autoComplete="off" />
              </StyledFormItem>
            </Col>
          </Row>

          {/* Email */}
          <Row style={{ flexDirection: 'row' }}>
            <Col span={24}>
              <StyledFormItem
                name="Email"
                label={
                  <p style={{ fontSize: '1rem', marginBottom: '0' }}>
                    <Translated id="visitors.email" />
                  </p>
                }
                rules={[
                  {
                    required: true,
                    message: intl.formatMessage({ id: 'visitors.form.warnings.email' }),
                  },
                  {
                    type: 'email',
                    message: intl.formatMessage({ id: 'form.warnings.emailFormat' }),
                  },
                ]}
              >
                <StyledInput type="email" placeholder="h.renner@company.com" autoComplete="off" />
              </StyledFormItem>
            </Col>
          </Row>
          {/* Phone Number */}
          <Row style={{ flexDirection: 'row' }}>
            <Col span={24}>
              <StyledFormItem
                name="PhoneNumber"
                label={
                  <p style={{ fontSize: '1rem', marginBottom: '0' }}>
                    <Translated id="visitors.phone" />
                  </p>
                }
                rules={[
                  {
                    pattern: /^[+]?[-\s0-9()]*$/,
                    message: intl.formatMessage({ id: 'form.warnings.phoneNumberFormat' }),
                  },
                ]}
              >
                <StyledInput type="tel" placeholder="+31 6 1234 5678" autoComplete="off" />
              </StyledFormItem>
            </Col>
          </Row>
          {/* Company Name */}
          <Row style={{ flexDirection: 'row' }}>
            <Col span={24}>
              <StyledFormItem
                name="CompanyName"
                label={
                  <p style={{ fontSize: '1rem', marginBottom: '0' }}>
                    <Translated id="visitors.companyName" />
                  </p>
                }
              >
                <StyledInput placeholder="Bluefield Smart Access" autoComplete="off" />
              </StyledFormItem>
            </Col>
          </Row>

          {/* Identifiers */}
          {!!identifiersData.length && (
            <Col span={24}>
              <h1 style={{ marginBottom: 15, marginTop: 10 }}>Identifiers</h1>
              <Divider />
            </Col>
          )}

          {identifiersData.map((data, index) => (
            <Row key={data.id} style={{ flexDirection: 'row' }}>
              <Col xs={24} sm={24} md={12}>
                <StyledFormItem
                  name={['Identifiers', index, 'Value']}
                  label={
                    <p style={{ fontSize: '1rem', marginBottom: '0' }}>
                      {isEmpty(data.valueLabel) ? 'Value' : data.valueLabel}
                    </p>
                  }
                  initialValue=""
                  dependencies={[['Identifiers', index, 'Name']]}
                  rules={[
                    {
                      required: data.requiredInSelfRegistration,
                      message: intl.formatMessage(
                        { id: 'identifiers.form.warnings.value' },
                        { label: isEmpty(data.valueLabel) ? 'Value' : data.valueLabel.toLowerCase() }
                      ),
                      validator: async (_, value) => {
                        const name = form.getFieldValue(['Identifiers', index, 'Name']);
                        if (!value && (!!name || data.requiredInSelfRegistration)) {
                          return Promise.reject(
                            new Error(
                              intl.formatMessage(
                                { id: 'identifiers.form.warnings.value' },
                                { label: isEmpty(data.valueLabel) ? 'Value' : data.valueLabel.toLowerCase() }
                              )
                            )
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <StyledInput placeholder={isEmpty(data.valueLabel) ? 'Value' : data.valueLabel} autoComplete="off" />
                </StyledFormItem>
              </Col>
              <Col xs={24} sm={24} md={12}>
                <StyledFormItem
                  name={['Identifiers', index, 'Name']}
                  label={
                    <p style={{ fontSize: '1rem', marginBottom: '0' }}>
                      {isEmpty(data.displayLabel) ? intl.formatMessage({ id: 'visits.form.name' }) : data.displayLabel}
                    </p>
                  }
                  initialValue=""
                  dependencies={[['Identifiers', index, 'Value']]}
                  rules={[
                    {
                      required: data.requiredInSelfRegistration,
                      message: intl.formatMessage(
                        { id: 'identifiers.form.warnings.name' },
                        {
                          label: isEmpty(data.displayLabel)
                            ? intl.formatMessage({ id: 'visits.form.name' })
                            : data.displayLabel.toLowerCase(),
                        }
                      ),
                      validator: async (_, name) => {
                        const value = form.getFieldValue(['Identifiers', index, 'Value']);
                        if (!name && (!!value || data.requiredInSelfRegistration)) {
                          return Promise.reject(
                            new Error(
                              intl.formatMessage(
                                { id: 'identifiers.form.warnings.name' },
                                {
                                  label: isEmpty(data.displayLabel)
                                    ? intl.formatMessage({ id: 'visits.form.name' })
                                    : data.displayLabel.toLowerCase(),
                                }
                              )
                            )
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <StyledInput
                    placeholder={
                      isEmpty(data.displayLabel) ? intl.formatMessage({ id: 'visits.form.name' }) : data.displayLabel
                    }
                    autoComplete="off"
                  />
                </StyledFormItem>
              </Col>
              <StyledFormItem
                hidden
                name={['Identifiers', index, 'Id']}
                initialValue={data.id}
                label={
                  <p style={{ fontSize: '1rem', marginBottom: '0' }}>
                    {isEmpty(data.displayLabel) ? intl.formatMessage({ id: 'visits.form.id' }) : data.displayLabel}
                  </p>
                }
              />
            </Row>
          ))}

          {/* agree to process */}

          <Row style={{ flexDirection: 'row', marginTop: 20 }}>
            <Col span={24}>
              <StyledFormItem
                name="agreeToProcess"
                valuePropName="checked"
                rules={[
                  {
                    validator: async (_, checked) => {
                      if (!checked) {
                        throw new Error(intl.formatMessage({ id: 'visits.form.warnings.agreeToProcess' }));
                      }
                      return checked;
                    },
                  },
                ]}
              >
                <StyledCheckbox>
                  <p style={{ fontSize: '1rem', marginBottom: '0' }}>
                    <Translated id="visitors.agreeToProcessLabel" />
                  </p>
                </StyledCheckbox>
              </StyledFormItem>
            </Col>
          </Row>
        </StyledForm>
      </StyledContainer>
    </Flex>
  );
};
